import { default as _91slug_93xTeolLh2SlMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bai-viet/[slug].vue?macro=true";
import { default as indexrHlCC6C1ZkMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bai-viet/index.vue?macro=true";
import { default as _91slug_93QOkdF2RcbrMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bai-viet/tag/[slug].vue?macro=true";
import { default as tagJAekGzm2MCMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bai-viet/tag.vue?macro=true";
import { default as bai_45vietCm0wWn4mXJMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bai-viet.vue?macro=true";
import { default as best_45selledT4SbwHd5AHMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/best-selled.vue?macro=true";
import { default as _91slug_93gJryyzSXy7Meta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bo-suu-tap/[slug].vue?macro=true";
import { default as bo_45suu_45tapgBQOlZnjJTMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bo-suu-tap.vue?macro=true";
import { default as _91slug_93NYWl66VY9jMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan/[slug].vue?macro=true";
import { default as dang_45kyBC4MH6dtmvMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan/dang-ky.vue?macro=true";
import { default as dang_45nhapnRObTU6GsWMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan/dang-nhap.vue?macro=true";
import { default as don_45hangzxWUmDCfowMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan/don-hang.vue?macro=true";
import { default as indexdj7XF0DitnMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan/index.vue?macro=true";
import { default as ca_45nhanqEE8120bhUMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan.vue?macro=true";
import { default as _91slug_931Mnb4q51DlMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/cau-hoi-thuong-gap/[slug].vue?macro=true";
import { default as cau_45hoi_45thuong_45gapcNzWZJkrZUMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/cau-hoi-thuong-gap.vue?macro=true";
import { default as _91slug_93TmWxJ6eKjpMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/chien-dich/[slug].vue?macro=true";
import { default as indexe4SxOTtBZWMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/chien-dich/index.vue?macro=true";
import { default as _91slug_93J7MkyA8qIHMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/chien-dich/tag/[slug].vue?macro=true";
import { default as tagYmsJCOsZI0Meta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/chien-dich/tag.vue?macro=true";
import { default as _91slug_93w75uoYd83ZMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/danh-muc/[slug].vue?macro=true";
import { default as indexgPlRRKoK9FMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/danh-muc/index.vue?macro=true";
import { default as danh_45mucKfmeFu1WJuMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/danh-muc.vue?macro=true";
import { default as gio_45hangAnRkRXt716Meta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/gio-hang.vue?macro=true";
import { default as hang_45moi_45veNmnpMhJFcUMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/hang-moi-ve.vue?macro=true";
import { default as he_45thong_45cua_45hangIaSBMGOKwQMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/he-thong-cua-hang.vue?macro=true";
import { default as _91slug_93hUnrLY8YufMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ho-tro/[slug].vue?macro=true";
import { default as _91slug_93b6HQQiycY0Meta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ho-tro/chi-tiet/[slug].vue?macro=true";
import { default as chi_45tietBhX4QBY7SzMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ho-tro/chi-tiet.vue?macro=true";
import { default as index2kHieaOvmFMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ho-tro/index.vue?macro=true";
import { default as ho_45tro7BlBc1DIa5Meta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ho-tro.vue?macro=true";
import { default as hot_45saleMiiSBw1wegMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/hot-sale.vue?macro=true";
import { default as desktop_45backup0nO3rvzqt3Meta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/index/desktop-backup.vue?macro=true";
import { default as desktopFkbIL6MEXoMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/index/desktop.vue?macro=true";
import { default as indexxDtrMZxZy7Meta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/index/index.vue?macro=true";
import { default as mobilebM4AsKE1xFMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/index/mobile.vue?macro=true";
import { default as lien_45heB25FrHYWQZMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/lien-he.vue?macro=true";
import { default as quang_45caoCdX2SiziHuMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/quang-cao.vue?macro=true";
import { default as san_45pham_45ban_45chay4QYx3XLYuqMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/san-pham-ban-chay.vue?macro=true";
import { default as _91slug_93gTMQuWPCnQMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/san-pham/[slug].vue?macro=true";
import { default as indexWBuI9SmWXBMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/san-pham/index.vue?macro=true";
import { default as san_45pham1iaQl8t3siMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/san-pham.vue?macro=true";
import { default as searchXsruw8RGtSMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/search.vue?macro=true";
import { default as _91id_93YBUO7YDF1wMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan/don-hang/[id].vue?macro=true";
import { default as indexq8MmtZmD4wMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan/don-hang/index.vue?macro=true";
import { default as don_45hang47mHfIXQEEMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan/don-hang.vue?macro=true";
import { default as index7V2tMTGVmxMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan/index.vue?macro=true";
import { default as yeu_45thichSoUk5nXxVYMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan/yeu-thich.vue?macro=true";
import { default as tai_45khoanUrAkKdcFzxMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan.vue?macro=true";
import { default as _91slug_93bt7DQaS2dTMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/thuong-hieu/[slug].vue?macro=true";
import { default as index3XzTUv2l1kMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/thuong-hieu/index.vue?macro=true";
import { default as thuong_45hieuFJfKt0zhBtMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/thuong-hieu.vue?macro=true";
import { default as tra_45cuu_45don_45hang2EGCodIranMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tra-cuu-don-hang.vue?macro=true";
import { default as trung_45tam_45ho_45tropsrBG0P8YiMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/trung-tam-ho-tro.vue?macro=true";
import { default as _91slug_93ytPjCiQbwNMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/video/[slug].vue?macro=true";
import { default as _91slug_93TgYEaorO98Meta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/video/danh-muc/[slug].vue?macro=true";
import { default as danh_45muckerNfuOgMXMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/video/danh-muc.vue?macro=true";
import { default as indexPCDuIBtuOXMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/video/index.vue?macro=true";
import { default as videoGvIvvMExITMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/video.vue?macro=true";
import { default as yeu_45thichghSjd02o3kMeta } from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/yeu-thich.vue?macro=true";
export default [
  {
    name: bai_45vietCm0wWn4mXJMeta?.name ?? undefined,
    path: bai_45vietCm0wWn4mXJMeta?.path ?? "/bai-viet",
    meta: bai_45vietCm0wWn4mXJMeta || {},
    alias: bai_45vietCm0wWn4mXJMeta?.alias || [],
    redirect: bai_45vietCm0wWn4mXJMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bai-viet.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93xTeolLh2SlMeta?.name ?? "bai-viet-slug___vi",
    path: _91slug_93xTeolLh2SlMeta?.path ?? ":slug()",
    meta: _91slug_93xTeolLh2SlMeta || {},
    alias: _91slug_93xTeolLh2SlMeta?.alias || [],
    redirect: _91slug_93xTeolLh2SlMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bai-viet/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexrHlCC6C1ZkMeta?.name ?? "bai-viet___vi",
    path: indexrHlCC6C1ZkMeta?.path ?? "",
    meta: indexrHlCC6C1ZkMeta || {},
    alias: indexrHlCC6C1ZkMeta?.alias || [],
    redirect: indexrHlCC6C1ZkMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bai-viet/index.vue").then(m => m.default || m)
  },
  {
    name: tagJAekGzm2MCMeta?.name ?? "bai-viet-tag___vi",
    path: tagJAekGzm2MCMeta?.path ?? "tag",
    meta: tagJAekGzm2MCMeta || {},
    alias: tagJAekGzm2MCMeta?.alias || [],
    redirect: tagJAekGzm2MCMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bai-viet/tag.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93QOkdF2RcbrMeta?.name ?? "bai-viet-tag-slug___vi",
    path: _91slug_93QOkdF2RcbrMeta?.path ?? ":slug()",
    meta: _91slug_93QOkdF2RcbrMeta || {},
    alias: _91slug_93QOkdF2RcbrMeta?.alias || [],
    redirect: _91slug_93QOkdF2RcbrMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bai-viet/tag/[slug].vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: bai_45vietCm0wWn4mXJMeta?.name ?? undefined,
    path: bai_45vietCm0wWn4mXJMeta?.path ?? "/cz/bai-viet",
    meta: bai_45vietCm0wWn4mXJMeta || {},
    alias: bai_45vietCm0wWn4mXJMeta?.alias || [],
    redirect: bai_45vietCm0wWn4mXJMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bai-viet.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93xTeolLh2SlMeta?.name ?? "bai-viet-slug___cz",
    path: _91slug_93xTeolLh2SlMeta?.path ?? ":slug()",
    meta: _91slug_93xTeolLh2SlMeta || {},
    alias: _91slug_93xTeolLh2SlMeta?.alias || [],
    redirect: _91slug_93xTeolLh2SlMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bai-viet/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexrHlCC6C1ZkMeta?.name ?? "bai-viet___cz",
    path: indexrHlCC6C1ZkMeta?.path ?? "",
    meta: indexrHlCC6C1ZkMeta || {},
    alias: indexrHlCC6C1ZkMeta?.alias || [],
    redirect: indexrHlCC6C1ZkMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bai-viet/index.vue").then(m => m.default || m)
  },
  {
    name: tagJAekGzm2MCMeta?.name ?? "bai-viet-tag___cz",
    path: tagJAekGzm2MCMeta?.path ?? "tag",
    meta: tagJAekGzm2MCMeta || {},
    alias: tagJAekGzm2MCMeta?.alias || [],
    redirect: tagJAekGzm2MCMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bai-viet/tag.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93QOkdF2RcbrMeta?.name ?? "bai-viet-tag-slug___cz",
    path: _91slug_93QOkdF2RcbrMeta?.path ?? ":slug()",
    meta: _91slug_93QOkdF2RcbrMeta || {},
    alias: _91slug_93QOkdF2RcbrMeta?.alias || [],
    redirect: _91slug_93QOkdF2RcbrMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bai-viet/tag/[slug].vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: best_45selledT4SbwHd5AHMeta?.name ?? "best-selled___vi",
    path: best_45selledT4SbwHd5AHMeta?.path ?? "/best-selled",
    meta: best_45selledT4SbwHd5AHMeta || {},
    alias: best_45selledT4SbwHd5AHMeta?.alias || [],
    redirect: best_45selledT4SbwHd5AHMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/best-selled.vue").then(m => m.default || m)
  },
  {
    name: best_45selledT4SbwHd5AHMeta?.name ?? "best-selled___cz",
    path: best_45selledT4SbwHd5AHMeta?.path ?? "/cz/best-selled",
    meta: best_45selledT4SbwHd5AHMeta || {},
    alias: best_45selledT4SbwHd5AHMeta?.alias || [],
    redirect: best_45selledT4SbwHd5AHMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/best-selled.vue").then(m => m.default || m)
  },
  {
    name: bo_45suu_45tapgBQOlZnjJTMeta?.name ?? "bo-suu-tap___vi",
    path: bo_45suu_45tapgBQOlZnjJTMeta?.path ?? "/bo-suu-tap",
    meta: bo_45suu_45tapgBQOlZnjJTMeta || {},
    alias: bo_45suu_45tapgBQOlZnjJTMeta?.alias || [],
    redirect: bo_45suu_45tapgBQOlZnjJTMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bo-suu-tap.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93gJryyzSXy7Meta?.name ?? "bo-suu-tap-slug___vi",
    path: _91slug_93gJryyzSXy7Meta?.path ?? ":slug()",
    meta: _91slug_93gJryyzSXy7Meta || {},
    alias: _91slug_93gJryyzSXy7Meta?.alias || [],
    redirect: _91slug_93gJryyzSXy7Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bo-suu-tap/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: bo_45suu_45tapgBQOlZnjJTMeta?.name ?? "bo-suu-tap___cz",
    path: bo_45suu_45tapgBQOlZnjJTMeta?.path ?? "/cz/bo-suu-tap",
    meta: bo_45suu_45tapgBQOlZnjJTMeta || {},
    alias: bo_45suu_45tapgBQOlZnjJTMeta?.alias || [],
    redirect: bo_45suu_45tapgBQOlZnjJTMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bo-suu-tap.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93gJryyzSXy7Meta?.name ?? "bo-suu-tap-slug___cz",
    path: _91slug_93gJryyzSXy7Meta?.path ?? ":slug()",
    meta: _91slug_93gJryyzSXy7Meta || {},
    alias: _91slug_93gJryyzSXy7Meta?.alias || [],
    redirect: _91slug_93gJryyzSXy7Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/bo-suu-tap/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: ca_45nhanqEE8120bhUMeta?.name ?? undefined,
    path: ca_45nhanqEE8120bhUMeta?.path ?? "/ca-nhan",
    meta: ca_45nhanqEE8120bhUMeta || {},
    alias: ca_45nhanqEE8120bhUMeta?.alias || [],
    redirect: ca_45nhanqEE8120bhUMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93NYWl66VY9jMeta?.name ?? "ca-nhan-slug___vi",
    path: _91slug_93NYWl66VY9jMeta?.path ?? ":slug()",
    meta: _91slug_93NYWl66VY9jMeta || {},
    alias: _91slug_93NYWl66VY9jMeta?.alias || [],
    redirect: _91slug_93NYWl66VY9jMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan/[slug].vue").then(m => m.default || m)
  },
  {
    name: dang_45kyBC4MH6dtmvMeta?.name ?? "ca-nhan-dang-ky___vi",
    path: dang_45kyBC4MH6dtmvMeta?.path ?? "dang-ky",
    meta: dang_45kyBC4MH6dtmvMeta || {},
    alias: dang_45kyBC4MH6dtmvMeta?.alias || [],
    redirect: dang_45kyBC4MH6dtmvMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan/dang-ky.vue").then(m => m.default || m)
  },
  {
    name: dang_45nhapnRObTU6GsWMeta?.name ?? "ca-nhan-dang-nhap___vi",
    path: dang_45nhapnRObTU6GsWMeta?.path ?? "dang-nhap",
    meta: dang_45nhapnRObTU6GsWMeta || {},
    alias: dang_45nhapnRObTU6GsWMeta?.alias || [],
    redirect: dang_45nhapnRObTU6GsWMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan/dang-nhap.vue").then(m => m.default || m)
  },
  {
    name: don_45hangzxWUmDCfowMeta?.name ?? "ca-nhan-don-hang___vi",
    path: don_45hangzxWUmDCfowMeta?.path ?? "don-hang",
    meta: don_45hangzxWUmDCfowMeta || {},
    alias: don_45hangzxWUmDCfowMeta?.alias || [],
    redirect: don_45hangzxWUmDCfowMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan/don-hang.vue").then(m => m.default || m)
  },
  {
    name: indexdj7XF0DitnMeta?.name ?? "ca-nhan___vi",
    path: indexdj7XF0DitnMeta?.path ?? "",
    meta: indexdj7XF0DitnMeta || {},
    alias: indexdj7XF0DitnMeta?.alias || [],
    redirect: indexdj7XF0DitnMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: ca_45nhanqEE8120bhUMeta?.name ?? undefined,
    path: ca_45nhanqEE8120bhUMeta?.path ?? "/cz/ca-nhan",
    meta: ca_45nhanqEE8120bhUMeta || {},
    alias: ca_45nhanqEE8120bhUMeta?.alias || [],
    redirect: ca_45nhanqEE8120bhUMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93NYWl66VY9jMeta?.name ?? "ca-nhan-slug___cz",
    path: _91slug_93NYWl66VY9jMeta?.path ?? ":slug()",
    meta: _91slug_93NYWl66VY9jMeta || {},
    alias: _91slug_93NYWl66VY9jMeta?.alias || [],
    redirect: _91slug_93NYWl66VY9jMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan/[slug].vue").then(m => m.default || m)
  },
  {
    name: dang_45kyBC4MH6dtmvMeta?.name ?? "ca-nhan-dang-ky___cz",
    path: dang_45kyBC4MH6dtmvMeta?.path ?? "dang-ky",
    meta: dang_45kyBC4MH6dtmvMeta || {},
    alias: dang_45kyBC4MH6dtmvMeta?.alias || [],
    redirect: dang_45kyBC4MH6dtmvMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan/dang-ky.vue").then(m => m.default || m)
  },
  {
    name: dang_45nhapnRObTU6GsWMeta?.name ?? "ca-nhan-dang-nhap___cz",
    path: dang_45nhapnRObTU6GsWMeta?.path ?? "dang-nhap",
    meta: dang_45nhapnRObTU6GsWMeta || {},
    alias: dang_45nhapnRObTU6GsWMeta?.alias || [],
    redirect: dang_45nhapnRObTU6GsWMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan/dang-nhap.vue").then(m => m.default || m)
  },
  {
    name: don_45hangzxWUmDCfowMeta?.name ?? "ca-nhan-don-hang___cz",
    path: don_45hangzxWUmDCfowMeta?.path ?? "don-hang",
    meta: don_45hangzxWUmDCfowMeta || {},
    alias: don_45hangzxWUmDCfowMeta?.alias || [],
    redirect: don_45hangzxWUmDCfowMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan/don-hang.vue").then(m => m.default || m)
  },
  {
    name: indexdj7XF0DitnMeta?.name ?? "ca-nhan___cz",
    path: indexdj7XF0DitnMeta?.path ?? "",
    meta: indexdj7XF0DitnMeta || {},
    alias: indexdj7XF0DitnMeta?.alias || [],
    redirect: indexdj7XF0DitnMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ca-nhan/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: cau_45hoi_45thuong_45gapcNzWZJkrZUMeta?.name ?? "cau-hoi-thuong-gap___vi",
    path: cau_45hoi_45thuong_45gapcNzWZJkrZUMeta?.path ?? "/cau-hoi-thuong-gap",
    meta: cau_45hoi_45thuong_45gapcNzWZJkrZUMeta || {},
    alias: cau_45hoi_45thuong_45gapcNzWZJkrZUMeta?.alias || [],
    redirect: cau_45hoi_45thuong_45gapcNzWZJkrZUMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/cau-hoi-thuong-gap.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_931Mnb4q51DlMeta?.name ?? "cau-hoi-thuong-gap-slug___vi",
    path: _91slug_931Mnb4q51DlMeta?.path ?? ":slug()",
    meta: _91slug_931Mnb4q51DlMeta || {},
    alias: _91slug_931Mnb4q51DlMeta?.alias || [],
    redirect: _91slug_931Mnb4q51DlMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/cau-hoi-thuong-gap/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: cau_45hoi_45thuong_45gapcNzWZJkrZUMeta?.name ?? "cau-hoi-thuong-gap___cz",
    path: cau_45hoi_45thuong_45gapcNzWZJkrZUMeta?.path ?? "/cz/cau-hoi-thuong-gap",
    meta: cau_45hoi_45thuong_45gapcNzWZJkrZUMeta || {},
    alias: cau_45hoi_45thuong_45gapcNzWZJkrZUMeta?.alias || [],
    redirect: cau_45hoi_45thuong_45gapcNzWZJkrZUMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/cau-hoi-thuong-gap.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_931Mnb4q51DlMeta?.name ?? "cau-hoi-thuong-gap-slug___cz",
    path: _91slug_931Mnb4q51DlMeta?.path ?? ":slug()",
    meta: _91slug_931Mnb4q51DlMeta || {},
    alias: _91slug_931Mnb4q51DlMeta?.alias || [],
    redirect: _91slug_931Mnb4q51DlMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/cau-hoi-thuong-gap/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_93TmWxJ6eKjpMeta?.name ?? "chien-dich-slug___vi",
    path: _91slug_93TmWxJ6eKjpMeta?.path ?? "/chien-dich/:slug()",
    meta: _91slug_93TmWxJ6eKjpMeta || {},
    alias: _91slug_93TmWxJ6eKjpMeta?.alias || [],
    redirect: _91slug_93TmWxJ6eKjpMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/chien-dich/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TmWxJ6eKjpMeta?.name ?? "chien-dich-slug___cz",
    path: _91slug_93TmWxJ6eKjpMeta?.path ?? "/cz/chien-dich/:slug()",
    meta: _91slug_93TmWxJ6eKjpMeta || {},
    alias: _91slug_93TmWxJ6eKjpMeta?.alias || [],
    redirect: _91slug_93TmWxJ6eKjpMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/chien-dich/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexe4SxOTtBZWMeta?.name ?? "chien-dich___vi",
    path: indexe4SxOTtBZWMeta?.path ?? "/chien-dich",
    meta: indexe4SxOTtBZWMeta || {},
    alias: indexe4SxOTtBZWMeta?.alias || [],
    redirect: indexe4SxOTtBZWMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/chien-dich/index.vue").then(m => m.default || m)
  },
  {
    name: indexe4SxOTtBZWMeta?.name ?? "chien-dich___cz",
    path: indexe4SxOTtBZWMeta?.path ?? "/cz/chien-dich",
    meta: indexe4SxOTtBZWMeta || {},
    alias: indexe4SxOTtBZWMeta?.alias || [],
    redirect: indexe4SxOTtBZWMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/chien-dich/index.vue").then(m => m.default || m)
  },
  {
    name: tagYmsJCOsZI0Meta?.name ?? "chien-dich-tag___vi",
    path: tagYmsJCOsZI0Meta?.path ?? "/chien-dich/tag",
    meta: tagYmsJCOsZI0Meta || {},
    alias: tagYmsJCOsZI0Meta?.alias || [],
    redirect: tagYmsJCOsZI0Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/chien-dich/tag.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93J7MkyA8qIHMeta?.name ?? "chien-dich-tag-slug___vi",
    path: _91slug_93J7MkyA8qIHMeta?.path ?? ":slug()",
    meta: _91slug_93J7MkyA8qIHMeta || {},
    alias: _91slug_93J7MkyA8qIHMeta?.alias || [],
    redirect: _91slug_93J7MkyA8qIHMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/chien-dich/tag/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: tagYmsJCOsZI0Meta?.name ?? "chien-dich-tag___cz",
    path: tagYmsJCOsZI0Meta?.path ?? "/cz/chien-dich/tag",
    meta: tagYmsJCOsZI0Meta || {},
    alias: tagYmsJCOsZI0Meta?.alias || [],
    redirect: tagYmsJCOsZI0Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/chien-dich/tag.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93J7MkyA8qIHMeta?.name ?? "chien-dich-tag-slug___cz",
    path: _91slug_93J7MkyA8qIHMeta?.path ?? ":slug()",
    meta: _91slug_93J7MkyA8qIHMeta || {},
    alias: _91slug_93J7MkyA8qIHMeta?.alias || [],
    redirect: _91slug_93J7MkyA8qIHMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/chien-dich/tag/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: danh_45mucKfmeFu1WJuMeta?.name ?? undefined,
    path: danh_45mucKfmeFu1WJuMeta?.path ?? "/danh-muc",
    meta: danh_45mucKfmeFu1WJuMeta || {},
    alias: danh_45mucKfmeFu1WJuMeta?.alias || [],
    redirect: danh_45mucKfmeFu1WJuMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/danh-muc.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93w75uoYd83ZMeta?.name ?? "danh-muc-slug___vi",
    path: _91slug_93w75uoYd83ZMeta?.path ?? ":slug()",
    meta: _91slug_93w75uoYd83ZMeta || {},
    alias: _91slug_93w75uoYd83ZMeta?.alias || [],
    redirect: _91slug_93w75uoYd83ZMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/danh-muc/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgPlRRKoK9FMeta?.name ?? "danh-muc___vi",
    path: indexgPlRRKoK9FMeta?.path ?? "",
    meta: indexgPlRRKoK9FMeta || {},
    alias: indexgPlRRKoK9FMeta?.alias || [],
    redirect: indexgPlRRKoK9FMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/danh-muc/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: danh_45mucKfmeFu1WJuMeta?.name ?? undefined,
    path: danh_45mucKfmeFu1WJuMeta?.path ?? "/cz/danh-muc",
    meta: danh_45mucKfmeFu1WJuMeta || {},
    alias: danh_45mucKfmeFu1WJuMeta?.alias || [],
    redirect: danh_45mucKfmeFu1WJuMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/danh-muc.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93w75uoYd83ZMeta?.name ?? "danh-muc-slug___cz",
    path: _91slug_93w75uoYd83ZMeta?.path ?? ":slug()",
    meta: _91slug_93w75uoYd83ZMeta || {},
    alias: _91slug_93w75uoYd83ZMeta?.alias || [],
    redirect: _91slug_93w75uoYd83ZMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/danh-muc/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgPlRRKoK9FMeta?.name ?? "danh-muc___cz",
    path: indexgPlRRKoK9FMeta?.path ?? "",
    meta: indexgPlRRKoK9FMeta || {},
    alias: indexgPlRRKoK9FMeta?.alias || [],
    redirect: indexgPlRRKoK9FMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/danh-muc/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: gio_45hangAnRkRXt716Meta?.name ?? "gio-hang___vi",
    path: gio_45hangAnRkRXt716Meta?.path ?? "/gio-hang",
    meta: gio_45hangAnRkRXt716Meta || {},
    alias: gio_45hangAnRkRXt716Meta?.alias || [],
    redirect: gio_45hangAnRkRXt716Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/gio-hang.vue").then(m => m.default || m)
  },
  {
    name: gio_45hangAnRkRXt716Meta?.name ?? "gio-hang___cz",
    path: gio_45hangAnRkRXt716Meta?.path ?? "/cz/gio-hang",
    meta: gio_45hangAnRkRXt716Meta || {},
    alias: gio_45hangAnRkRXt716Meta?.alias || [],
    redirect: gio_45hangAnRkRXt716Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/gio-hang.vue").then(m => m.default || m)
  },
  {
    name: hang_45moi_45veNmnpMhJFcUMeta?.name ?? "hang-moi-ve___vi",
    path: hang_45moi_45veNmnpMhJFcUMeta?.path ?? "/hang-moi-ve",
    meta: hang_45moi_45veNmnpMhJFcUMeta || {},
    alias: hang_45moi_45veNmnpMhJFcUMeta?.alias || [],
    redirect: hang_45moi_45veNmnpMhJFcUMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/hang-moi-ve.vue").then(m => m.default || m)
  },
  {
    name: hang_45moi_45veNmnpMhJFcUMeta?.name ?? "hang-moi-ve___cz",
    path: hang_45moi_45veNmnpMhJFcUMeta?.path ?? "/cz/hang-moi-ve",
    meta: hang_45moi_45veNmnpMhJFcUMeta || {},
    alias: hang_45moi_45veNmnpMhJFcUMeta?.alias || [],
    redirect: hang_45moi_45veNmnpMhJFcUMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/hang-moi-ve.vue").then(m => m.default || m)
  },
  {
    name: he_45thong_45cua_45hangIaSBMGOKwQMeta?.name ?? "he-thong-cua-hang___vi",
    path: he_45thong_45cua_45hangIaSBMGOKwQMeta?.path ?? "/he-thong-cua-hang",
    meta: he_45thong_45cua_45hangIaSBMGOKwQMeta || {},
    alias: he_45thong_45cua_45hangIaSBMGOKwQMeta?.alias || [],
    redirect: he_45thong_45cua_45hangIaSBMGOKwQMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/he-thong-cua-hang.vue").then(m => m.default || m)
  },
  {
    name: he_45thong_45cua_45hangIaSBMGOKwQMeta?.name ?? "he-thong-cua-hang___cz",
    path: he_45thong_45cua_45hangIaSBMGOKwQMeta?.path ?? "/cz/he-thong-cua-hang",
    meta: he_45thong_45cua_45hangIaSBMGOKwQMeta || {},
    alias: he_45thong_45cua_45hangIaSBMGOKwQMeta?.alias || [],
    redirect: he_45thong_45cua_45hangIaSBMGOKwQMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/he-thong-cua-hang.vue").then(m => m.default || m)
  },
  {
    name: ho_45tro7BlBc1DIa5Meta?.name ?? undefined,
    path: ho_45tro7BlBc1DIa5Meta?.path ?? "/ho-tro",
    meta: ho_45tro7BlBc1DIa5Meta || {},
    alias: ho_45tro7BlBc1DIa5Meta?.alias || [],
    redirect: ho_45tro7BlBc1DIa5Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ho-tro.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93hUnrLY8YufMeta?.name ?? "ho-tro-slug___vi",
    path: _91slug_93hUnrLY8YufMeta?.path ?? ":slug()",
    meta: _91slug_93hUnrLY8YufMeta || {},
    alias: _91slug_93hUnrLY8YufMeta?.alias || [],
    redirect: _91slug_93hUnrLY8YufMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ho-tro/[slug].vue").then(m => m.default || m)
  },
  {
    name: chi_45tietBhX4QBY7SzMeta?.name ?? "ho-tro-chi-tiet___vi",
    path: chi_45tietBhX4QBY7SzMeta?.path ?? "chi-tiet",
    meta: chi_45tietBhX4QBY7SzMeta || {},
    alias: chi_45tietBhX4QBY7SzMeta?.alias || [],
    redirect: chi_45tietBhX4QBY7SzMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ho-tro/chi-tiet.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93b6HQQiycY0Meta?.name ?? "ho-tro-chi-tiet-slug___vi",
    path: _91slug_93b6HQQiycY0Meta?.path ?? ":slug()",
    meta: _91slug_93b6HQQiycY0Meta || {},
    alias: _91slug_93b6HQQiycY0Meta?.alias || [],
    redirect: _91slug_93b6HQQiycY0Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ho-tro/chi-tiet/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: index2kHieaOvmFMeta?.name ?? "ho-tro___vi",
    path: index2kHieaOvmFMeta?.path ?? "",
    meta: index2kHieaOvmFMeta || {},
    alias: index2kHieaOvmFMeta?.alias || [],
    redirect: index2kHieaOvmFMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ho-tro/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: ho_45tro7BlBc1DIa5Meta?.name ?? undefined,
    path: ho_45tro7BlBc1DIa5Meta?.path ?? "/cz/ho-tro",
    meta: ho_45tro7BlBc1DIa5Meta || {},
    alias: ho_45tro7BlBc1DIa5Meta?.alias || [],
    redirect: ho_45tro7BlBc1DIa5Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ho-tro.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93hUnrLY8YufMeta?.name ?? "ho-tro-slug___cz",
    path: _91slug_93hUnrLY8YufMeta?.path ?? ":slug()",
    meta: _91slug_93hUnrLY8YufMeta || {},
    alias: _91slug_93hUnrLY8YufMeta?.alias || [],
    redirect: _91slug_93hUnrLY8YufMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ho-tro/[slug].vue").then(m => m.default || m)
  },
  {
    name: chi_45tietBhX4QBY7SzMeta?.name ?? "ho-tro-chi-tiet___cz",
    path: chi_45tietBhX4QBY7SzMeta?.path ?? "chi-tiet",
    meta: chi_45tietBhX4QBY7SzMeta || {},
    alias: chi_45tietBhX4QBY7SzMeta?.alias || [],
    redirect: chi_45tietBhX4QBY7SzMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ho-tro/chi-tiet.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93b6HQQiycY0Meta?.name ?? "ho-tro-chi-tiet-slug___cz",
    path: _91slug_93b6HQQiycY0Meta?.path ?? ":slug()",
    meta: _91slug_93b6HQQiycY0Meta || {},
    alias: _91slug_93b6HQQiycY0Meta?.alias || [],
    redirect: _91slug_93b6HQQiycY0Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ho-tro/chi-tiet/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: index2kHieaOvmFMeta?.name ?? "ho-tro___cz",
    path: index2kHieaOvmFMeta?.path ?? "",
    meta: index2kHieaOvmFMeta || {},
    alias: index2kHieaOvmFMeta?.alias || [],
    redirect: index2kHieaOvmFMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/ho-tro/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: hot_45saleMiiSBw1wegMeta?.name ?? "hot-sale___vi",
    path: hot_45saleMiiSBw1wegMeta?.path ?? "/hot-sale",
    meta: hot_45saleMiiSBw1wegMeta || {},
    alias: hot_45saleMiiSBw1wegMeta?.alias || [],
    redirect: hot_45saleMiiSBw1wegMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/hot-sale.vue").then(m => m.default || m)
  },
  {
    name: hot_45saleMiiSBw1wegMeta?.name ?? "hot-sale___cz",
    path: hot_45saleMiiSBw1wegMeta?.path ?? "/cz/hot-sale",
    meta: hot_45saleMiiSBw1wegMeta || {},
    alias: hot_45saleMiiSBw1wegMeta?.alias || [],
    redirect: hot_45saleMiiSBw1wegMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/hot-sale.vue").then(m => m.default || m)
  },
  {
    name: desktop_45backup0nO3rvzqt3Meta?.name ?? "index-desktop-backup___vi",
    path: desktop_45backup0nO3rvzqt3Meta?.path ?? "//desktop-backup",
    meta: desktop_45backup0nO3rvzqt3Meta || {},
    alias: desktop_45backup0nO3rvzqt3Meta?.alias || [],
    redirect: desktop_45backup0nO3rvzqt3Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/index/desktop-backup.vue").then(m => m.default || m)
  },
  {
    name: desktop_45backup0nO3rvzqt3Meta?.name ?? "index-desktop-backup___cz",
    path: desktop_45backup0nO3rvzqt3Meta?.path ?? "/cz//desktop-backup",
    meta: desktop_45backup0nO3rvzqt3Meta || {},
    alias: desktop_45backup0nO3rvzqt3Meta?.alias || [],
    redirect: desktop_45backup0nO3rvzqt3Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/index/desktop-backup.vue").then(m => m.default || m)
  },
  {
    name: desktopFkbIL6MEXoMeta?.name ?? "index-desktop___vi",
    path: desktopFkbIL6MEXoMeta?.path ?? "//desktop",
    meta: desktopFkbIL6MEXoMeta || {},
    alias: desktopFkbIL6MEXoMeta?.alias || [],
    redirect: desktopFkbIL6MEXoMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/index/desktop.vue").then(m => m.default || m)
  },
  {
    name: desktopFkbIL6MEXoMeta?.name ?? "index-desktop___cz",
    path: desktopFkbIL6MEXoMeta?.path ?? "/cz//desktop",
    meta: desktopFkbIL6MEXoMeta || {},
    alias: desktopFkbIL6MEXoMeta?.alias || [],
    redirect: desktopFkbIL6MEXoMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/index/desktop.vue").then(m => m.default || m)
  },
  {
    name: indexxDtrMZxZy7Meta?.name ?? "index___vi",
    path: indexxDtrMZxZy7Meta?.path ?? "/",
    meta: indexxDtrMZxZy7Meta || {},
    alias: indexxDtrMZxZy7Meta?.alias || [],
    redirect: indexxDtrMZxZy7Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexxDtrMZxZy7Meta?.name ?? "index___cz",
    path: indexxDtrMZxZy7Meta?.path ?? "/cz",
    meta: indexxDtrMZxZy7Meta || {},
    alias: indexxDtrMZxZy7Meta?.alias || [],
    redirect: indexxDtrMZxZy7Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: mobilebM4AsKE1xFMeta?.name ?? "index-mobile___vi",
    path: mobilebM4AsKE1xFMeta?.path ?? "//mobile",
    meta: mobilebM4AsKE1xFMeta || {},
    alias: mobilebM4AsKE1xFMeta?.alias || [],
    redirect: mobilebM4AsKE1xFMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/index/mobile.vue").then(m => m.default || m)
  },
  {
    name: mobilebM4AsKE1xFMeta?.name ?? "index-mobile___cz",
    path: mobilebM4AsKE1xFMeta?.path ?? "/cz//mobile",
    meta: mobilebM4AsKE1xFMeta || {},
    alias: mobilebM4AsKE1xFMeta?.alias || [],
    redirect: mobilebM4AsKE1xFMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/index/mobile.vue").then(m => m.default || m)
  },
  {
    name: lien_45heB25FrHYWQZMeta?.name ?? "lien-he___vi",
    path: lien_45heB25FrHYWQZMeta?.path ?? "/lien-he",
    meta: lien_45heB25FrHYWQZMeta || {},
    alias: lien_45heB25FrHYWQZMeta?.alias || [],
    redirect: lien_45heB25FrHYWQZMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/lien-he.vue").then(m => m.default || m)
  },
  {
    name: lien_45heB25FrHYWQZMeta?.name ?? "lien-he___cz",
    path: lien_45heB25FrHYWQZMeta?.path ?? "/cz/lien-he",
    meta: lien_45heB25FrHYWQZMeta || {},
    alias: lien_45heB25FrHYWQZMeta?.alias || [],
    redirect: lien_45heB25FrHYWQZMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/lien-he.vue").then(m => m.default || m)
  },
  {
    name: quang_45caoCdX2SiziHuMeta?.name ?? "quang-cao___vi",
    path: quang_45caoCdX2SiziHuMeta?.path ?? "/quang-cao",
    meta: quang_45caoCdX2SiziHuMeta || {},
    alias: quang_45caoCdX2SiziHuMeta?.alias || [],
    redirect: quang_45caoCdX2SiziHuMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/quang-cao.vue").then(m => m.default || m)
  },
  {
    name: quang_45caoCdX2SiziHuMeta?.name ?? "quang-cao___cz",
    path: quang_45caoCdX2SiziHuMeta?.path ?? "/cz/quang-cao",
    meta: quang_45caoCdX2SiziHuMeta || {},
    alias: quang_45caoCdX2SiziHuMeta?.alias || [],
    redirect: quang_45caoCdX2SiziHuMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/quang-cao.vue").then(m => m.default || m)
  },
  {
    name: san_45pham_45ban_45chay4QYx3XLYuqMeta?.name ?? "san-pham-ban-chay___vi",
    path: san_45pham_45ban_45chay4QYx3XLYuqMeta?.path ?? "/san-pham-ban-chay",
    meta: san_45pham_45ban_45chay4QYx3XLYuqMeta || {},
    alias: san_45pham_45ban_45chay4QYx3XLYuqMeta?.alias || [],
    redirect: san_45pham_45ban_45chay4QYx3XLYuqMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/san-pham-ban-chay.vue").then(m => m.default || m)
  },
  {
    name: san_45pham_45ban_45chay4QYx3XLYuqMeta?.name ?? "san-pham-ban-chay___cz",
    path: san_45pham_45ban_45chay4QYx3XLYuqMeta?.path ?? "/cz/san-pham-ban-chay",
    meta: san_45pham_45ban_45chay4QYx3XLYuqMeta || {},
    alias: san_45pham_45ban_45chay4QYx3XLYuqMeta?.alias || [],
    redirect: san_45pham_45ban_45chay4QYx3XLYuqMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/san-pham-ban-chay.vue").then(m => m.default || m)
  },
  {
    name: san_45pham1iaQl8t3siMeta?.name ?? undefined,
    path: san_45pham1iaQl8t3siMeta?.path ?? "/san-pham",
    meta: san_45pham1iaQl8t3siMeta || {},
    alias: san_45pham1iaQl8t3siMeta?.alias || [],
    redirect: san_45pham1iaQl8t3siMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/san-pham.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93gTMQuWPCnQMeta?.name ?? "san-pham-slug___vi",
    path: _91slug_93gTMQuWPCnQMeta?.path ?? ":slug()",
    meta: _91slug_93gTMQuWPCnQMeta || {},
    alias: _91slug_93gTMQuWPCnQMeta?.alias || [],
    redirect: _91slug_93gTMQuWPCnQMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/san-pham/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexWBuI9SmWXBMeta?.name ?? "san-pham___vi",
    path: indexWBuI9SmWXBMeta?.path ?? "",
    meta: indexWBuI9SmWXBMeta || {},
    alias: indexWBuI9SmWXBMeta?.alias || [],
    redirect: indexWBuI9SmWXBMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/san-pham/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: san_45pham1iaQl8t3siMeta?.name ?? undefined,
    path: san_45pham1iaQl8t3siMeta?.path ?? "/cz/san-pham",
    meta: san_45pham1iaQl8t3siMeta || {},
    alias: san_45pham1iaQl8t3siMeta?.alias || [],
    redirect: san_45pham1iaQl8t3siMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/san-pham.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93gTMQuWPCnQMeta?.name ?? "san-pham-slug___cz",
    path: _91slug_93gTMQuWPCnQMeta?.path ?? ":slug()",
    meta: _91slug_93gTMQuWPCnQMeta || {},
    alias: _91slug_93gTMQuWPCnQMeta?.alias || [],
    redirect: _91slug_93gTMQuWPCnQMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/san-pham/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexWBuI9SmWXBMeta?.name ?? "san-pham___cz",
    path: indexWBuI9SmWXBMeta?.path ?? "",
    meta: indexWBuI9SmWXBMeta || {},
    alias: indexWBuI9SmWXBMeta?.alias || [],
    redirect: indexWBuI9SmWXBMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/san-pham/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: searchXsruw8RGtSMeta?.name ?? "search___vi",
    path: searchXsruw8RGtSMeta?.path ?? "/search",
    meta: searchXsruw8RGtSMeta || {},
    alias: searchXsruw8RGtSMeta?.alias || [],
    redirect: searchXsruw8RGtSMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchXsruw8RGtSMeta?.name ?? "search___cz",
    path: searchXsruw8RGtSMeta?.path ?? "/cz/search",
    meta: searchXsruw8RGtSMeta || {},
    alias: searchXsruw8RGtSMeta?.alias || [],
    redirect: searchXsruw8RGtSMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/search.vue").then(m => m.default || m)
  },
  {
    name: tai_45khoanUrAkKdcFzxMeta?.name ?? undefined,
    path: tai_45khoanUrAkKdcFzxMeta?.path ?? "/tai-khoan",
    meta: tai_45khoanUrAkKdcFzxMeta || {},
    alias: tai_45khoanUrAkKdcFzxMeta?.alias || [],
    redirect: tai_45khoanUrAkKdcFzxMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan.vue").then(m => m.default || m),
    children: [
  {
    name: don_45hang47mHfIXQEEMeta?.name ?? undefined,
    path: don_45hang47mHfIXQEEMeta?.path ?? "don-hang",
    meta: don_45hang47mHfIXQEEMeta || {},
    alias: don_45hang47mHfIXQEEMeta?.alias || [],
    redirect: don_45hang47mHfIXQEEMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan/don-hang.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93YBUO7YDF1wMeta?.name ?? "tai-khoan-don-hang-id___vi",
    path: _91id_93YBUO7YDF1wMeta?.path ?? ":id()",
    meta: _91id_93YBUO7YDF1wMeta || {},
    alias: _91id_93YBUO7YDF1wMeta?.alias || [],
    redirect: _91id_93YBUO7YDF1wMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan/don-hang/[id].vue").then(m => m.default || m)
  },
  {
    name: indexq8MmtZmD4wMeta?.name ?? "tai-khoan-don-hang___vi",
    path: indexq8MmtZmD4wMeta?.path ?? "",
    meta: indexq8MmtZmD4wMeta || {},
    alias: indexq8MmtZmD4wMeta?.alias || [],
    redirect: indexq8MmtZmD4wMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan/don-hang/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index7V2tMTGVmxMeta?.name ?? "tai-khoan___vi",
    path: index7V2tMTGVmxMeta?.path ?? "",
    meta: index7V2tMTGVmxMeta || {},
    alias: index7V2tMTGVmxMeta?.alias || [],
    redirect: index7V2tMTGVmxMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan/index.vue").then(m => m.default || m)
  },
  {
    name: yeu_45thichSoUk5nXxVYMeta?.name ?? "tai-khoan-yeu-thich___vi",
    path: yeu_45thichSoUk5nXxVYMeta?.path ?? "yeu-thich",
    meta: yeu_45thichSoUk5nXxVYMeta || {},
    alias: yeu_45thichSoUk5nXxVYMeta?.alias || [],
    redirect: yeu_45thichSoUk5nXxVYMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan/yeu-thich.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tai_45khoanUrAkKdcFzxMeta?.name ?? undefined,
    path: tai_45khoanUrAkKdcFzxMeta?.path ?? "/cz/tai-khoan",
    meta: tai_45khoanUrAkKdcFzxMeta || {},
    alias: tai_45khoanUrAkKdcFzxMeta?.alias || [],
    redirect: tai_45khoanUrAkKdcFzxMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan.vue").then(m => m.default || m),
    children: [
  {
    name: don_45hang47mHfIXQEEMeta?.name ?? undefined,
    path: don_45hang47mHfIXQEEMeta?.path ?? "don-hang",
    meta: don_45hang47mHfIXQEEMeta || {},
    alias: don_45hang47mHfIXQEEMeta?.alias || [],
    redirect: don_45hang47mHfIXQEEMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan/don-hang.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93YBUO7YDF1wMeta?.name ?? "tai-khoan-don-hang-id___cz",
    path: _91id_93YBUO7YDF1wMeta?.path ?? ":id()",
    meta: _91id_93YBUO7YDF1wMeta || {},
    alias: _91id_93YBUO7YDF1wMeta?.alias || [],
    redirect: _91id_93YBUO7YDF1wMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan/don-hang/[id].vue").then(m => m.default || m)
  },
  {
    name: indexq8MmtZmD4wMeta?.name ?? "tai-khoan-don-hang___cz",
    path: indexq8MmtZmD4wMeta?.path ?? "",
    meta: indexq8MmtZmD4wMeta || {},
    alias: indexq8MmtZmD4wMeta?.alias || [],
    redirect: indexq8MmtZmD4wMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan/don-hang/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index7V2tMTGVmxMeta?.name ?? "tai-khoan___cz",
    path: index7V2tMTGVmxMeta?.path ?? "",
    meta: index7V2tMTGVmxMeta || {},
    alias: index7V2tMTGVmxMeta?.alias || [],
    redirect: index7V2tMTGVmxMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan/index.vue").then(m => m.default || m)
  },
  {
    name: yeu_45thichSoUk5nXxVYMeta?.name ?? "tai-khoan-yeu-thich___cz",
    path: yeu_45thichSoUk5nXxVYMeta?.path ?? "yeu-thich",
    meta: yeu_45thichSoUk5nXxVYMeta || {},
    alias: yeu_45thichSoUk5nXxVYMeta?.alias || [],
    redirect: yeu_45thichSoUk5nXxVYMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tai-khoan/yeu-thich.vue").then(m => m.default || m)
  }
]
  },
  {
    name: thuong_45hieuFJfKt0zhBtMeta?.name ?? undefined,
    path: thuong_45hieuFJfKt0zhBtMeta?.path ?? "/thuong-hieu",
    meta: thuong_45hieuFJfKt0zhBtMeta || {},
    alias: thuong_45hieuFJfKt0zhBtMeta?.alias || [],
    redirect: thuong_45hieuFJfKt0zhBtMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/thuong-hieu.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93bt7DQaS2dTMeta?.name ?? "thuong-hieu-slug___vi",
    path: _91slug_93bt7DQaS2dTMeta?.path ?? ":slug()",
    meta: _91slug_93bt7DQaS2dTMeta || {},
    alias: _91slug_93bt7DQaS2dTMeta?.alias || [],
    redirect: _91slug_93bt7DQaS2dTMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/thuong-hieu/[slug].vue").then(m => m.default || m)
  },
  {
    name: index3XzTUv2l1kMeta?.name ?? "thuong-hieu___vi",
    path: index3XzTUv2l1kMeta?.path ?? "",
    meta: index3XzTUv2l1kMeta || {},
    alias: index3XzTUv2l1kMeta?.alias || [],
    redirect: index3XzTUv2l1kMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/thuong-hieu/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: thuong_45hieuFJfKt0zhBtMeta?.name ?? undefined,
    path: thuong_45hieuFJfKt0zhBtMeta?.path ?? "/cz/thuong-hieu",
    meta: thuong_45hieuFJfKt0zhBtMeta || {},
    alias: thuong_45hieuFJfKt0zhBtMeta?.alias || [],
    redirect: thuong_45hieuFJfKt0zhBtMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/thuong-hieu.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93bt7DQaS2dTMeta?.name ?? "thuong-hieu-slug___cz",
    path: _91slug_93bt7DQaS2dTMeta?.path ?? ":slug()",
    meta: _91slug_93bt7DQaS2dTMeta || {},
    alias: _91slug_93bt7DQaS2dTMeta?.alias || [],
    redirect: _91slug_93bt7DQaS2dTMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/thuong-hieu/[slug].vue").then(m => m.default || m)
  },
  {
    name: index3XzTUv2l1kMeta?.name ?? "thuong-hieu___cz",
    path: index3XzTUv2l1kMeta?.path ?? "",
    meta: index3XzTUv2l1kMeta || {},
    alias: index3XzTUv2l1kMeta?.alias || [],
    redirect: index3XzTUv2l1kMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/thuong-hieu/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tra_45cuu_45don_45hang2EGCodIranMeta?.name ?? "tra-cuu-don-hang___vi",
    path: tra_45cuu_45don_45hang2EGCodIranMeta?.path ?? "/tra-cuu-don-hang",
    meta: tra_45cuu_45don_45hang2EGCodIranMeta || {},
    alias: tra_45cuu_45don_45hang2EGCodIranMeta?.alias || [],
    redirect: tra_45cuu_45don_45hang2EGCodIranMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tra-cuu-don-hang.vue").then(m => m.default || m)
  },
  {
    name: tra_45cuu_45don_45hang2EGCodIranMeta?.name ?? "tra-cuu-don-hang___cz",
    path: tra_45cuu_45don_45hang2EGCodIranMeta?.path ?? "/cz/tra-cuu-don-hang",
    meta: tra_45cuu_45don_45hang2EGCodIranMeta || {},
    alias: tra_45cuu_45don_45hang2EGCodIranMeta?.alias || [],
    redirect: tra_45cuu_45don_45hang2EGCodIranMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/tra-cuu-don-hang.vue").then(m => m.default || m)
  },
  {
    name: trung_45tam_45ho_45tropsrBG0P8YiMeta?.name ?? "trung-tam-ho-tro___vi",
    path: trung_45tam_45ho_45tropsrBG0P8YiMeta?.path ?? "/trung-tam-ho-tro",
    meta: trung_45tam_45ho_45tropsrBG0P8YiMeta || {},
    alias: trung_45tam_45ho_45tropsrBG0P8YiMeta?.alias || [],
    redirect: trung_45tam_45ho_45tropsrBG0P8YiMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/trung-tam-ho-tro.vue").then(m => m.default || m)
  },
  {
    name: trung_45tam_45ho_45tropsrBG0P8YiMeta?.name ?? "trung-tam-ho-tro___cz",
    path: trung_45tam_45ho_45tropsrBG0P8YiMeta?.path ?? "/cz/trung-tam-ho-tro",
    meta: trung_45tam_45ho_45tropsrBG0P8YiMeta || {},
    alias: trung_45tam_45ho_45tropsrBG0P8YiMeta?.alias || [],
    redirect: trung_45tam_45ho_45tropsrBG0P8YiMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/trung-tam-ho-tro.vue").then(m => m.default || m)
  },
  {
    name: videoGvIvvMExITMeta?.name ?? undefined,
    path: videoGvIvvMExITMeta?.path ?? "/video",
    meta: videoGvIvvMExITMeta || {},
    alias: videoGvIvvMExITMeta?.alias || [],
    redirect: videoGvIvvMExITMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/video.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93ytPjCiQbwNMeta?.name ?? "video-slug___vi",
    path: _91slug_93ytPjCiQbwNMeta?.path ?? ":slug()",
    meta: _91slug_93ytPjCiQbwNMeta || {},
    alias: _91slug_93ytPjCiQbwNMeta?.alias || [],
    redirect: _91slug_93ytPjCiQbwNMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/video/[slug].vue").then(m => m.default || m)
  },
  {
    name: danh_45muckerNfuOgMXMeta?.name ?? "video-danh-muc___vi",
    path: danh_45muckerNfuOgMXMeta?.path ?? "danh-muc",
    meta: danh_45muckerNfuOgMXMeta || {},
    alias: danh_45muckerNfuOgMXMeta?.alias || [],
    redirect: danh_45muckerNfuOgMXMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/video/danh-muc.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93TgYEaorO98Meta?.name ?? "video-danh-muc-slug___vi",
    path: _91slug_93TgYEaorO98Meta?.path ?? ":slug()",
    meta: _91slug_93TgYEaorO98Meta || {},
    alias: _91slug_93TgYEaorO98Meta?.alias || [],
    redirect: _91slug_93TgYEaorO98Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/video/danh-muc/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexPCDuIBtuOXMeta?.name ?? "video___vi",
    path: indexPCDuIBtuOXMeta?.path ?? "",
    meta: indexPCDuIBtuOXMeta || {},
    alias: indexPCDuIBtuOXMeta?.alias || [],
    redirect: indexPCDuIBtuOXMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/video/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: videoGvIvvMExITMeta?.name ?? undefined,
    path: videoGvIvvMExITMeta?.path ?? "/cz/video",
    meta: videoGvIvvMExITMeta || {},
    alias: videoGvIvvMExITMeta?.alias || [],
    redirect: videoGvIvvMExITMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/video.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93ytPjCiQbwNMeta?.name ?? "video-slug___cz",
    path: _91slug_93ytPjCiQbwNMeta?.path ?? ":slug()",
    meta: _91slug_93ytPjCiQbwNMeta || {},
    alias: _91slug_93ytPjCiQbwNMeta?.alias || [],
    redirect: _91slug_93ytPjCiQbwNMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/video/[slug].vue").then(m => m.default || m)
  },
  {
    name: danh_45muckerNfuOgMXMeta?.name ?? "video-danh-muc___cz",
    path: danh_45muckerNfuOgMXMeta?.path ?? "danh-muc",
    meta: danh_45muckerNfuOgMXMeta || {},
    alias: danh_45muckerNfuOgMXMeta?.alias || [],
    redirect: danh_45muckerNfuOgMXMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/video/danh-muc.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93TgYEaorO98Meta?.name ?? "video-danh-muc-slug___cz",
    path: _91slug_93TgYEaorO98Meta?.path ?? ":slug()",
    meta: _91slug_93TgYEaorO98Meta || {},
    alias: _91slug_93TgYEaorO98Meta?.alias || [],
    redirect: _91slug_93TgYEaorO98Meta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/video/danh-muc/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexPCDuIBtuOXMeta?.name ?? "video___cz",
    path: indexPCDuIBtuOXMeta?.path ?? "",
    meta: indexPCDuIBtuOXMeta || {},
    alias: indexPCDuIBtuOXMeta?.alias || [],
    redirect: indexPCDuIBtuOXMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/video/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: yeu_45thichghSjd02o3kMeta?.name ?? "yeu-thich___vi",
    path: yeu_45thichghSjd02o3kMeta?.path ?? "/yeu-thich",
    meta: yeu_45thichghSjd02o3kMeta || {},
    alias: yeu_45thichghSjd02o3kMeta?.alias || [],
    redirect: yeu_45thichghSjd02o3kMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/yeu-thich.vue").then(m => m.default || m)
  },
  {
    name: yeu_45thichghSjd02o3kMeta?.name ?? "yeu-thich___cz",
    path: yeu_45thichghSjd02o3kMeta?.path ?? "/cz/yeu-thich",
    meta: yeu_45thichghSjd02o3kMeta || {},
    alias: yeu_45thichghSjd02o3kMeta?.alias || [],
    redirect: yeu_45thichghSjd02o3kMeta?.redirect,
    component: () => import("/usr/loidirieng/cosmeticscz/cosmeticscz.com/pages/yeu-thich.vue").then(m => m.default || m)
  }
]