import revive_payload_client_4sVQNw7RlN from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_eTVJQYlCmx from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import useDirectives_W0tXrzvhuP from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/useDirectives.mjs";
import createBootstrap_ywvW1KIeJ9 from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/createBootstrap.mjs";
import plugin_client_LcKgStRyi6 from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import i18n_yfWm7jX06p from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import directive_gIN9ZiQDG6 from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/plugins/directive.js";
import scrollToTop_jnWzptp5jr from "/usr/loidirieng/cosmeticscz/cosmeticscz.com/plugins/scrollToTop.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  plugin_eTVJQYlCmx,
  useDirectives_W0tXrzvhuP,
  createBootstrap_ywvW1KIeJ9,
  plugin_client_LcKgStRyi6,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  directive_gIN9ZiQDG6,
  scrollToTop_jnWzptp5jr
]